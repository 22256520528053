.socialShare {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--hdsplus-spacing-05);

	& li {
		position: relative;
		display: grid;
		place-items: center;
	}
}

.shareButton {
	width: 24px;
	height: 24px;
	background: none;
	appearance: none;
	border: none;
	padding: 0;
	margin: 0;
}

.icon {
	color: var(--token-color-foreground-faint);
	transition: 250ms ease;
	transition-property: color;

	&:hover {
		cursor: pointer;
		color: var(--token-color-foreground-strong);
	}
}

.tooltip {
	composes: g-type-body-small from global;
	font-size: 14px;
	display: flex;
	align-items: center;
	gap: 2px;
	padding: 2px 8px;
	border-radius: 4px;
	position: absolute;
	top: -24px;
	background-color: var(--token-color-surface-strong);
	color: var(--token-color-foreground-faint);
}
