.root {
	position: relative;
}

.inner {
	--gridTemplateColumns: unset;
	--gap: var(--hdsplus-spacing-08);
	
	display: grid;
	gap: var(--gap);
	padding-top: var(--hdsplus-spacing-08);
	
	@media (--medium-up) {
		--gridTemplateColumns: 150px 1fr;
	}
	
	@media (--large) {
		padding-top: var(--hdsplus-spacing-11);
	}

	&.hasNoPadding {
		padding: 0;
	}
}

.dropdown {
	background: var(--background-color-primary-override, var(--token-color-surface-primary));
	padding: var(--hdsplus-spacing-08) 0;
	position: sticky;
	top: 62px;
	z-index: 1;
	width: 100%;

	@media (--medium-up) {
		display: none;
	}
}

.row {
	display: grid;
	gap: var(--hdsplus-spacing-05) var(--gap);
	grid-template-columns: var(--gridTemplateColumns);

	&.hidden {
		display: none;

		@media (--medium-up) {
			display: grid;
		}
	}
}

.time {
	font-weight: 600;
}

.rowItems {
	display: grid;
	gap: var(--gap);

	@media (--medium-up) {
		grid-template-columns: repeat(var(--columns), 1fr);
	}
}

.rowItem {
	--gridStart: 1;
	--gridEnd: -1;

	grid-row: 1 / span 1;

	@media (--medium-up) {
		grid-row: 1 / span 1;
		grid-column: var(--gridStart) / var(--gridEnd);
	}
}

.agendaHeadersWrapper {
	display: none;

	@media (--medium-up) {
		display: block;
	}
}

.agendaHeaders {
	display: grid;
	gap: var(--gap);
	grid-template-columns: repeat(var(--columns), 1fr);
}

.agendaHeader {
	font-weight: 600;
}

.divider {
	height: 1px;
	width: 100%;
	background: var(--token-color-border-strong);
}

.isDesktop {
	display: none;

	@media (--medium-up) {
		display: block;
	}
}

.isMobile {
	display: block;

	@media (--medium-up) {
		display: none;
	}
}


