.root {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-07);
	padding: var(--hdsplus-spacing-08);
	height: 100%;
}

.text {
	font-weight: 600;
}

.badges {
	display: flex;
	gap: var(--hdsplus-spacing-04);
	margin: var(--hdsplus-spacing-04) 0 0;
	padding: 0;
	list-style-type:none;
}
