.root {
	--none: 0;
	--small: var(--hdsplus-spacing-08);
	--medium: var(--hdsplus-spacing-10);
	--large: var(--hdsplus-spacing-12);

	@media (--medium-up) {
		--small: var(--hdsplus-spacing-10);
		--medium: var(--hdsplus-spacing-12);
		--large: var(--hdsplus-spacing-14);
	}

	@media (--large) {
		--small: var(--hdsplus-spacing-12);
		--medium: var(--hdsplus-spacing-14);
		--large: var(--hdsplus-spacing-16);
	}

	/* Background */

	background-color: var(
		--background-color-primary-override,
		var(--token-color-surface-primary)
	);

	&.faint {
		background-color: var(
			--background-color-faint-override,
			var(--token-color-surface-faint)
		);
	}

	&.transparent {
		background-color: transparent;
	}

	/* Padding */

	&.paddingTop-none {
		padding-top: var(--none);
	}

	&.paddingTop-small {
		padding-top: var(--small);
	}

	&.paddingTop-medium {
		padding-top: var(--medium);
	}

	&.paddingTop-large {
		padding-top: var(--large);
	}

	&.paddingBottom-none {
		padding-bottom: var(--none);
	}

	&.paddingBottom-small {
		padding-bottom: var(--small);
	}

	&.paddingBottom-medium {
		padding-bottom: var(--medium);
	}

	&.paddingBottom-large {
		padding-bottom: var(--large);
	}
}
