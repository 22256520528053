.root {
	composes: g-grid-container from global;
}

.panels {
	display: grid;
}

.panelWrapper {
	grid-area: 1 / -1;
	display: block;
}

.panel {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: grid;
	gap: var(--hdsplus-spacing-12);
}

.panel[hidden] {
	display: block;
}

.mobile {
	@media (--medium-up) {
		display: none;
	}
}

.desktop {
	display: none;
	@media (--medium-up) {
		display: block;
	}
}

.mobilePanel {
	display: grid;
	gap: var(--hdsplus-spacing-07);
}
